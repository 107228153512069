import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Dropdown, FormControl, InputGroup} from 'react-bootstrap';
import {deleteIcon, plusIcon} from '../../../../../components/Icons/Icons';

const OpenEnded = ({curOptionContent, setNewOption, addOption, onDeleteOption, index, onLeaveInput, inputError, setInputError}) => {
  const optTypes = useSelector(state => state?.getSubsectionInfo?.quest_types[0]?.field_types);
  const { invalidQuestions } = useSelector(state => state.cudQuestions);
  const [curOptionContentState, setCurOptionContentState] = useState(null);
  const [optionDropdown, setOptionDropdown] = useState({});
  const [optionName, setOptionName] = useState('');
  const [optionPlaceholder, setOptionPlaceholder] = useState('');

  useEffect(() => {
    let order = curOptionContentState?.order || index + 1;
    let type = optionDropdown?.id;
    let id = curOptionContentState?.id;

    (!!optionName || !!optionPlaceholder) && setNewOption(prevState => {
      let newState = [];
      if (prevState.some(({ id: optionId }) => optionId === id)) {
        newState = prevState.map((option) => option.id === id ? {...option, u_id: id, placeholder: optionPlaceholder.trim(), type: type, 'name': optionName} : { ...option });
      } else {
        newState = [...prevState, { id: id, placeholder: optionPlaceholder.trim(), order: order, type: type, 'name': optionName }];
      }

      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionName, optionPlaceholder]);

  useEffect(() => {
    optTypes && setOptionDropdown(optTypes[0]);
  }, [optTypes]);

  useEffect(() => {
    setCurOptionContentState(curOptionContent);
  }, [curOptionContent]);

  const onChangeQuestionType = (e) => {
    setOptionDropdown({id: +e.target.id, type: e.target.textContent});
    let type = +e.target.id;
    let order = curOptionContentState.order || index + 1;
    let id = curOptionContentState.id;

    setNewOption(prevState => {
      let newState = [];
      if (prevState.some(({ id: optionId }) => optionId === id)) {
        newState = prevState.map((option) => option.id === id ? {...option, type: type} : { ...option });
      } else {
        newState = [...prevState, { id: id, order: order, type: type }];
      }

      return newState;
    });
  };

  const handleOnOptionChange = (e) => {
    const {name, value} = e.target;

    if (name === 'option_name') {
      const hasSpaces = /\s/g.test(value);
      const hasValidLength = (value.length > 15);
      const isValid = !!value;

      setInputError({
        ...inputError,
        hasSpaces,
        hasValidLength,
        isValid
      });

      setOptionName(value);
    } else {
      setOptionPlaceholder(value);
    }
  };

    return (
      <>
        <InputGroup className="mt-3 groupName_question_option">
          <FormControl
            name="option_name"
            aria-label="Option"
            aria-describedby="inputGroup-sizing-default"
            placeholder="option_name"
            className="optionName"
            onChange={e => handleOnOptionChange(e)}
            defaultValue={curOptionContentState?.name}
            disabled={curOptionContentState?.is_default}
            maxLength={15}
            value={curOptionContentState?.name ? curOptionContentState?.name : optionName.trim()}
          />
          <FormControl
            id={curOptionContentState?.id}
            name={curOptionContentState?.name}
            aria-label="Option"
            aria-describedby="inputGroup-sizing-default"
            placeholder={`${optionDropdown?.type || curOptionContentState?.type.type} field`}
            onChange={e => handleOnOptionChange(e)}
            defaultValue={curOptionContentState?.placeholder}
            onKeyPress={e => onLeaveInput(e)}
            disabled={curOptionContentState?.is_default}
            maxLength={25}
          />
          {(index === 0) && !curOptionContentState?.is_default && <span onClick={() => addOption()}>{plusIcon}</span>}
          {(index !== 0) && !curOptionContentState?.is_default && <span onClick={() => onDeleteOption(curOptionContentState)} className="xIcon">{deleteIcon}</span>}
          <Dropdown className="groupName_question_dropdown" name="type">
            <Dropdown.Toggle disabled={curOptionContentState?.is_default} id="question-dropdown-options">
              {(optionDropdown?.type !== 'Plain Text') ? optionDropdown?.type : curOptionContentState?.type.type}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                optTypes?.map(type => {
                  return (
                    <React.Fragment
                      key={type.id}
                    >
                      {!((type.type !== 'Plain Text') && (type.type !== 'Date') && (type.type !== 'Email')) &&
                        <Dropdown.Item
                          id={type.id}
                          onClick={(e) => onChangeQuestionType(e)}
                        >
                          {type.type}
                        </Dropdown.Item>}
                    </React.Fragment>
                  );
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
        <p className="mt-2 error">
          {invalidQuestions?.invalid_fields?.find((field) => curOptionContent?.id === field?.id)?.name[0]}
        </p>
      </>
    );
};

export default OpenEnded;