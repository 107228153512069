import {UserActionsTypes} from '../types';

const initialState = {
    subsections: [],
    loading: false,
};

export const getSubsectionContentReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case UserActionsTypes.SUBSECTION_CONTENT_LOADING:
            return {
                ...state,
                loading: true
            };
        case UserActionsTypes.SUBSECTION_CONTENT:
            return {
                ...state,
                subsections: [...payload],
                loading: false
            };
        default:
            return state;
    }
};

export default getSubsectionContentReducer;
