export const UserActionsTypes = {
    NEW_INFO: 'NEW_INFO',
    SUBSECTION_CONTENT: 'SUBSECTION_CONTENT',
    QUESTION_TYPES: 'QUESTION_TYPES',
    CREATE_QUESTIONS: 'CREATE_QUESTIONS',
    UPDATE_QUESTIONS: 'UPDATE_QUESTIONS',
    DELETE_QUESTIONS: 'DELETE_QUESTIONS',
    INVALID_QUESTION: 'INVALID_QUESTION',
    QUESTIONS_LOADING: 'QUESTIONS_LOADING',
    SUBSECTION_CONTENT_LOADING: 'SUBSECTION_CONTENT_LOADING',
    TOGGLE_NEW_INFO_LOADING: 'TOGGLE_NEW_INFO_LOADING',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    TOGGLE_LOADING_SIGN_IN: 'TOGGLE_LOADING_SIGN_IN',
    LOG_OUT: 'LOG_OUT',
    TOGGLE_LOADING_LOG_OUT: 'TOGGLE_LOADING_LOG_OUT',
    PUBLISH_QUESTIONS: 'PUBLISH_QUESTIONS',
    PUBLISH_QUESTIONS_LOADING: 'PUBLISH_QUESTIONS_LOADING',
};