import {useEffect} from 'react';


const Dashboard = () => {

    useEffect(() => {
        !!localStorage.getItem('openedSubsection') && localStorage.removeItem('openedSubsection');
    }, []);

    return (
      <h1>Hello Dashboard</h1>
    );
};

export default Dashboard;