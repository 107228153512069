import {UserActionsTypes} from '../types';
import axios from 'axios';
import {SERVER} from '../../utils/constants';

export const getSubsectionContent = (subsectionId) => async (dispatch, getState) => {
    dispatch(setLoader());
    axios.get(`${SERVER}api/subsection-questions/${subsectionId}/`)
        .then(response => {
            dispatch(subsectionContent(response.data));
        })
        .catch(error => {
            dispatch(subsectionContent(error));
        });
};

const subsectionContent = (subsectionInfo) => {
    return {
        type: UserActionsTypes.SUBSECTION_CONTENT,
        payload: subsectionInfo
    };
};

const setLoader = () => {
    return {
        type: UserActionsTypes.SUBSECTION_CONTENT_LOADING,
    };
};