import {UserActionsTypes} from '../types';
import axios from 'axios';
import {SERVER} from '../../utils/constants';
import {getSubsectionContent} from './getSubsectionContent.action';

export const createQuestions = (newQuestion) => async (dispatch, getState) => {
    const {getSubsectionInfo: {sidebarInfo}} = getState();

    dispatch(setLoader());
    axios.post(`${SERVER}api/question-create/`, {
        visa_id: sidebarInfo[0].id,
        name: newQuestion.name,
        field_set: newQuestion.field_set,
        order: newQuestion.order,
        subsection: newQuestion.subsection,
        group_id: newQuestion.group_id,
        title: newQuestion.question_name,
        type: newQuestion.type,
        explanation: newQuestion.explanation,
        hint: newQuestion.hint,
    })
        .then(response => {
            if (response.data?.invalid_fields?.length !== 0) {
                dispatch(setInvalidQuestions(response.data));
                dispatch(createQuestionsContent(response.data));
            } else {
                dispatch(createQuestionsContent(response.data));
            }
            // todo figure out if necessary
            // dispatch(getSubsectionContent(newQuestion.subsection));
        })
        .catch(error => {
            dispatch(createQuestionsContent(error));
        });
};

export const updateQuestions = (updatedQuestion) => async (dispatch, getState) => {
    const {getSubsectionInfo: {sidebarInfo}} = getState();

    dispatch(setLoader());
    axios.put(`${SERVER}api/question/${updatedQuestion.question_id}/`, {
        visa_id: sidebarInfo[0].id,
        name: updatedQuestion.name,
        question: updatedQuestion.question_id,
        field_set: updatedQuestion.field_set,
        order: updatedQuestion.order,
        title: updatedQuestion.question_name,
        type: updatedQuestion.type,
        explanation: updatedQuestion.explanation,
        hint: updatedQuestion.hint,
        deleted_fields: updatedQuestion.deleted_fields
    })
        .then(response => {
            if (response.data?.invalid_fields?.length !== 0) {
                dispatch(setInvalidQuestions(response.data));
                dispatch(updateQuestionsContent(response.data));
            } else {
                dispatch(updateQuestionsContent(response.data));
            }
            //todo figure out if necessary
            // dispatch(getSubsectionContent(updatedQuestion.subsection));
        })
        .catch(error => {
            dispatch(updateQuestionsContent(error));
        });
};

export const deleteQuestions = (deletedQuestion_id, subsection_id) => async (dispatch) => {
    dispatch(setLoader());
    axios.delete(`${SERVER}api/question/${deletedQuestion_id}/`, )
        .then(response => {
            dispatch(deleteQuestionsContent(response.data));
            dispatch(getSubsectionContent(subsection_id));
        })
        .catch(error => {
            dispatch(deleteQuestionsContent(error));
        });
};

const createQuestionsContent = (newQuestions) => {
    return {
        type: UserActionsTypes.CREATE_QUESTIONS,
        payload: newQuestions
    };
};

const updateQuestionsContent = (updatedQuestions) => {
    return {
        type: UserActionsTypes.CREATE_QUESTIONS,
        payload: updatedQuestions
    };
};

const setInvalidQuestions = (questions) => {
    return {
        type: UserActionsTypes.INVALID_QUESTION,
        payload: { ...questions }
    };
};

const deleteQuestionsContent = (deletedQuestions) => {
    return {
        type: UserActionsTypes.CREATE_QUESTIONS,
        payload: deletedQuestions
    };
};

const setLoader = () => {
    return {
        type: UserActionsTypes.QUESTIONS_LOADING,
    };
};