import {UserActionsTypes} from '../types';
import axios from 'axios';
import {SERVER} from '../../utils/constants';

export const publishQuestions = (visaId) => async (dispatch, getState) => {
    dispatch(setLoader());
    axios.get(`${SERVER}api/send-petition-data/${visaId}`)
        .then(response => {
            dispatch(publishQuestionResponse(response.data));
        })
        .catch(error => {
            dispatch(publishQuestionResponse(error));
        });
};

const publishQuestionResponse = (response) => {
    return {
        type: UserActionsTypes.PUBLISH_QUESTIONS,
        payload: response
    };
};

const setLoader = () => {
    return {
        type: UserActionsTypes.PUBLISH_QUESTIONS_LOADING,
    };
};