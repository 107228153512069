import {UserActionsTypes} from '../types';

const initialState = {
    response: {},
    loading: false,
};

export const publishQuestionsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case UserActionsTypes.PUBLISH_QUESTIONS_LOADING:
            return {
                ...state,
                loading: true
            };
        case UserActionsTypes.PUBLISH_QUESTIONS:
            return {
                ...state,
                response: {...payload},
                loading: false
            };
        default:
            return state;
    }
};

export default publishQuestionsReducer;
