import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getSubsectionInfo} from '../../../store/Actions';
import {Questions} from './Questions';
import {GroupDeleteModal} from '../../Modals/GroupDeleteModal';

import {Button, Dropdown, FormControl, InputGroup, OverlayTrigger, Row} from 'react-bootstrap';
import {deleteIcon, dotsIcon, duplicateIcon, plusIcon, questionIcon} from '../../../components/Icons/Icons';

const initialQuestion = {
    explanation: null,
    field_set: [{
        content: null,
        id: `uId_${Date.now()}`,
        name: null,
        order: null,
        placeholder: '',
        question: null,
        is_default: false,
        type: {
            description: null,
            id: null,
            type: 'Plain Text',
        }
    }],
    hint: null,
    id: null,
    order: null,
    subsection: null,
    title: '',
    is_default: false,
    is_new: true,
    type: {id: null, type: 'Open Ended', description: null}
};

const optionsPopover = (
  <div className="addingBlock">
    {questionIcon}
    <div>
      <span>Add Question</span>
      <p>Just start writing with plain text.</p>
    </div>
  </div>
);

const Grouped = ({subSectionId, curGroupContent, index}) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.getSubsectionInfo.loading);
    const [curGroupQuestionSet, setCurGroupQuestionSet] = useState([]);
    const [newGroupName, setNewGroupName] = useState({});
    const [showGroupDeleteModal, setShowGroupDeleteModal] = useState(false);

    useEffect(() => {
        !isLoading && setShowGroupDeleteModal(false);
    }, [isLoading]);

    useEffect(() => {
        setCurGroupQuestionSet(curGroupContent?.question_set);
    }, [curGroupContent]);

    const handleGroupName = (e) => {
        const {id, name, value} = e.target;
        let curGroupOrder = curGroupContent.order || index + 1;

        setNewGroupName({...newGroupName, subsection: subSectionId, order: curGroupOrder, id, [name]: value});
    };

    const saveGroupName = () => {
        dispatch(getSubsectionInfo(newGroupName));
        setNewGroupName({...newGroupName, group_name: newGroupName.group_name.trim()});
    };

    const cancelGroupNameChange = () => {
        setNewGroupName({...newGroupName, group_name: curGroupContent?.title});
    };

    const handleGroupDelete = () => {
        dispatch(getSubsectionInfo(curGroupContent.id));
    };

    const onAddQuestion = () => {
        setCurGroupQuestionSet([...curGroupQuestionSet, initialQuestion]);
    };

    return (
      <Row className="mainContentBlock cloud">
        <Row className="mainContent">
          {
              (curGroupContent && curGroupContent.id) ?
                <Dropdown className="dots">
                  <Dropdown.Toggle id="dropdown-options">
                    <span>{dotsIcon}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={curGroupContent?.is_new}
                      className={curGroupContent?.is_new && 'disabled'}
                    >
                      {duplicateIcon} Duplicate
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setShowGroupDeleteModal(!showGroupDeleteModal)}
                      disabled={curGroupContent?.is_default || curGroupContent?.is_new}
                      className={curGroupContent?.is_default || curGroupContent?.is_new && 'disabled'}
                    >
                      {deleteIcon} Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> : null
            }
          <InputGroup className="mt-3 groupName">
            <FormControl
              id={curGroupContent?.id}
              name="group_name"
              value={newGroupName.group_name}
              aria-label="Group name"
              aria-describedby="inputGroup-sizing-default"
              placeholder="Group Name"
              defaultValue={curGroupContent?.title}
              onChange={e => handleGroupName(e)}
            />
          </InputGroup>
          {
              newGroupName.group_name && (newGroupName.group_name !== curGroupContent?.title) &&
                <div className="groupNameActions">
                  <span onClick={saveGroupName}> &#10003; </span>
                  <span onClick={cancelGroupNameChange}> &#10005; </span>
                </div>
            }
          {
              curGroupQuestionSet.map((question, index) => {
                  return (
                    <Questions key={question.id} curGroup={curGroupContent} subSectionId={subSectionId} index={index} curQuestionContent={question} />
                  );
              })
            }
          {!curGroupQuestionSet.filter(e => e.title === '').length && curGroupContent?.title &&
            <OverlayTrigger
              placement="bottom-start"
              overlay={optionsPopover}
            >
              {({ref, ...triggerHandler}) => (
                <Button
                  variant="light"
                  ref={ref}
                  {...triggerHandler}
                  className="d-inline-flex align-items-center overlayBtn questBtn"
                  onClick={() => onAddQuestion()}
                >
                  {plusIcon}
                </Button>
                )}
            </OverlayTrigger>}
        </Row>
        <GroupDeleteModal
          show={showGroupDeleteModal}
          onHide={() => setShowGroupDeleteModal(!showGroupDeleteModal)}
          handleGroupDelete={handleGroupDelete}
        />
      </Row>
    );
};


export default Grouped;