import {Button, Container, Dropdown, Navbar, Spinner} from 'react-bootstrap';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {publishQuestions} from '../../store/Actions/publishQuestions.action';
import {avatar, avatarIcon, helpIcon, setting, signOutIcon} from '../Icons/Icons';
import './HeadNavbar.scss';

const HeadNavbar = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state?.publishQuestions?.loading);
    const sidebarContent = useSelector(state => state.getSubsectionInfo.sidebarInfo['0']);

    const signOut = () => {
        localStorage.removeItem('admin');
        localStorage.removeItem('openedSubsection');
        window.location.reload(false);
    };

    const publish = () => {
        dispatch(publishQuestions(sidebarContent?.id));
    };

    return (
      <Navbar className="headNavbar">
        <Container>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <Button className="publishBtn" onClick={() => publish()}>
                {!isLoading ?
                  <span>Publish</span> :
                  <Spinner animation="border" />}
              </Button>
              <Dropdown className="navbarOptions">
                <Dropdown.Toggle id="dropdown-options">
                  {avatar}
                  <span className="user">Name Surname</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>{avatarIcon} Profile</Dropdown.Item>
                  <Dropdown.Item>{helpIcon} Help Center</Dropdown.Item>
                  <Dropdown.Item>{setting} Settings</Dropdown.Item>
                  <Dropdown.Item onClick={signOut}>{signOutIcon} Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
};

export default HeadNavbar;