import {UserActionsTypes} from '../types';

const initialState = {
    newQuestions: {},
    updatedQuestions: {},
    deletedQuestions: {},
    invalidQuestions: {},
    loading: false,
};

export const cudQuestionsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case UserActionsTypes.QUESTIONS_LOADING:
            return {
                ...state,
                loading: true
            };
        case UserActionsTypes.CREATE_QUESTIONS:
            return {
                ...state,
                newQuestions: {
                    ...payload
                },
                loading: false
            };
        case UserActionsTypes.UPDATE_QUESTIONS:
            return {
                ...state,
                updatedQuestions: {
                    ...payload
                },
                loading: false
            };
        case UserActionsTypes.DELETE_QUESTIONS:
            return {
                ...state,
                deletedQuestions: {
                    ...payload
                },
                loading: false
            };
        case UserActionsTypes.INVALID_QUESTION:
            return {
                ...state,
                invalidQuestions: { ...payload }
            };
        default:
            return state;
    }
};

export default cudQuestionsReducer;
