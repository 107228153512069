import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getSubsectionContent} from '../../store/Actions';
import {Grouped} from './Grouped';
import {Ungrouped} from './Ungrouped';
import {
    Button,
    Container, OverlayTrigger,
    Row,
} from 'react-bootstrap';
import {
    groupIcon,
    leftArrow, plusIcon,
    rightArrow
} from '../../components/Icons/Icons';

const initialGroup = {
    id: null,
    order: null,
    question_set: [],
    subsection: null,
    title: '',
    is_default: false,
    is_new: true,
};

const groupPopover = (
  <div className="addingBlock">
    {groupIcon}
    <div>
      <span>Add Group</span>
      <p>Just start writing with plain text.</p>
    </div>
  </div>
);

const Subsection = () => {
    const dispatch = useDispatch();
    const sidebarInfo = useSelector(state => state?.getSubsectionInfo.sidebarInfo['0']);
    const subSectionContent = useSelector(state => state?.getSubsectionContent.subsections);
    const [subSectionName, setSubSectionName] = useState('');
    const [subSectionId, setSubSectionId] = useState();
    const [subSectionContentState, setSubSectionContentState] = useState([]);
    const [isGrouped, setIsGrouped] = useState(true);

    useEffect(() => {
        !!localStorage.getItem('openedSubsection') && dispatch(getSubsectionContent(localStorage.getItem('openedSubsection')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        subSectionContentState.map((elem, index) => {
            setIsGrouped(!elem.hasOwnProperty('group'));
        });
    });

    useEffect(() => {
        subSectionId && dispatch(getSubsectionContent(subSectionId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, sidebarInfo]);

    useEffect(() => {
        setSubSectionContentState(subSectionContent);
    }, [subSectionContent]);

    useEffect(() => {
        sidebarInfo?.sections.map(e => e.subsections.map(subsection => {
                if (subsection.id === subSectionContent[0]?.subsection) {
                    setSubSectionName(subsection.title);
                    setSubSectionId(subsection.id);
                }
            }));
    }, [sidebarInfo?.sections, subSectionContent]);

    const onAddGroup = () => {
        setSubSectionContentState([...subSectionContentState, initialGroup]);
    };

    return (
      <Container>
        <Row className="mainSubsectionContent">
          {
              subSectionName &&
                <div className="pageTitle">
                  <div>
                    {/*<div className="pageTitle_arrows">*/}
                    {/*  {leftArrow}*/}
                    {/*  {rightArrow}*/}
                    {/*</div>*/}
                    <h1>{subSectionName}</h1>
                    {/*<ButtonGroup className="radioBtnBlock">*/}
                    {/*  {radios.map((radio, idx) => (*/}
                    {/*    <ToggleButton*/}
                    {/*      key={idx}*/}
                    {/*      id={`radio-${idx}`}*/}
                    {/*      type="radio"*/}
                    {/*      name="radio"*/}
                    {/*      className="radioBtn"*/}
                    {/*      value={radio.value}*/}
                    {/*      checked={radioValue === radio.value}*/}
                    {/*      onChange={(e) => onRadioChange(e)}*/}
                    {/*    >*/}
                    {/*      {radio.name}*/}
                    {/*    </ToggleButton>*/}
                    {/*  ))}*/}
                    {/*</ButtonGroup>*/}
                  </div>
                </div>
            }
          <div className="scrollableBlock">
            {
                isGrouped ? subSectionContentState.map((elem, index) => {
                    return (
                      <Grouped key={elem.id} index={index} subSectionId={subSectionId} curGroupContent={elem} />
                    );
                }) : <Ungrouped subSectionId={subSectionId} curQuestionContent={subSectionContentState} />
              }
            {
                !subSectionContentState.length || !subSectionContentState.filter(e => e.title === '').length && isGrouped &&
                  <OverlayTrigger
                    placement="right"
                    overlay={groupPopover}
                  >
                    {({ref, ...triggerHandler}) => (
                      <Button
                        variant="light"
                        ref={ref}
                        {...triggerHandler}
                        className="d-inline-flex align-items-center groupBtn"
                        onClick={() => onAddGroup()}
                      >
                        {plusIcon}
                      </Button>
                    )}
                  </OverlayTrigger>
            }
          </div>
        </Row>
      </Container>
    );
};


export default Subsection;