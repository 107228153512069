import {Button, Modal, Spinner} from 'react-bootstrap';
import React from 'react';
import {useSelector} from 'react-redux';

export const QuestionDeleteModal = ({show, onHide, handleQuestionDelete}) => {
  const isLoading = useSelector(state => state.cudQuestions.loading);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      dialogClassName="modal-size"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <p>
          Are you sure you want to delete the question?
        </p>
        <div className="actions">
          <Button variant="light" onClick={onHide}>Cancel</Button>
          <Button variant="primary" onClick={handleQuestionDelete}>
            {
              !isLoading ?
                <span>Delete</span>
                  : <Spinner animation="border" />
              }
          </Button>
        </div>
      </Modal.Body>
    </Modal>
    );
};