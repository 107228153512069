import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createQuestions, deleteQuestions, updateQuestions} from '../../../../store/Actions';
import {QuestionDeleteModal} from '../../../Modals/QuestionDeleteModal';
import {MultiSelect, OpenEnded, SingleSelect} from './Options';

import {Button, Dropdown, FormControl, InputGroup, Spinner} from 'react-bootstrap';
import {
  deleteIcon,
  disableIcon,
  dotsIcon,
  duplicateIcon,
  hintIcon,
  infoIcon
} from '../../../../components/Icons/Icons';

const inputsVerificationError = {
  hasSpaces: false,
  hasValidLength: false,
  isNotUnique: false,
  isValid: false
};

const Questions = ({curGroup, curQuestionContent, subSectionId, index}) => {
  const dispatch = useDispatch();
  const questTypes = useSelector(state => state?.getSubsectionInfo.quest_types);
  const isLoading = useSelector(state => state.cudQuestions.loading);
  const [curQuestionContentState, setCurQuestionContentState] = useState(null);
  const [newQuestion, setNewQuestion] = useState({});
  const [questionDropdown, setQuestionDropdown] = useState();
  const [newOption, setNewOption] = useState([]);
  const [showQuestionDeleteModal, setShowQuestionDeleteModal] = useState(false);
  const [isNewAddedOption, setIsNewAddedOption] = useState(false);
  const [removedDeletedOptions, setRemovedDeletedOptions] = useState([]);
  const [inputError, setInputError] = useState(inputsVerificationError);

  let curGroupOrder = curQuestionContentState?.order || index + 1;
  let curQuestionId = curQuestionContentState?.id || null;
  let curQuestionType = curQuestionContentState?.type.id || questionDropdown?.id || questTypes[0]?.id;

  useEffect(() => {
    !isLoading && setShowQuestionDeleteModal(false);
  }, [isLoading]);

  useEffect(() => {
    setCurQuestionContentState(curQuestionContent);
  }, [curQuestionContent]);

  useEffect(() => {
    questTypes[0] && setQuestionDropdown(questTypes[0]);
  }, [questTypes]);

  useEffect(() => {
    setNewQuestion({
      ...newQuestion,
      subsection: subSectionId,
      field_set: newOption,
      type: curQuestionType,
      group_id: curGroup.id,
      order: curGroupOrder,
      question_id: curQuestionId,
      deleted_fields: removedDeletedOptions,
    });
    setIsNewAddedOption(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOption, removedDeletedOptions]);

  const handleOnQuestionChange = (e) => {
    const {name, value} = e.target;

    setNewQuestion({
      ...newQuestion,
      subsection: subSectionId,
      field_set: newOption,
      type: curQuestionType,
      group_id: curGroup.id,
      order: curGroupOrder,
      question_id: curQuestionId,
      [name]: value,
    });
  };

  const onChangeQuestionType = (e) => {
    setQuestionDropdown({id: +e.target.id, type: e.target.textContent});
    setNewQuestion({
      ...newQuestion, type: +e.target.id,
    });
    setCurQuestionContentState({...curQuestionContentState, type: {id: +e.target.id, type: e.target.textContent, description: null}});
  };

  const onAddExplain = () => {
    setCurQuestionContentState({...curQuestionContentState, explanation: ''});
  };

  const onDeleteExplain = () => {
    setCurQuestionContentState({...curQuestionContentState, explanation: null});
    setNewQuestion({
      ...newQuestion,
      question_id: curQuestionId,
      subsection: subSectionId,
      field_set: [],
      type: curQuestionType,
      explanation: null,
    });
  };

  const onAddHint = () => {
    setCurQuestionContentState({...curQuestionContentState, hint: ''});
  };

  const onDeleteHint = () => {
    setCurQuestionContentState({...curQuestionContentState, hint: null});
    setNewQuestion({
      ...newQuestion,
      question_id: curQuestionId,
      subsection: subSectionId,
      field_set: [],
      type: curQuestionType,
      hint: null,
    });
  };

  const addOption = () => {
    if (curQuestionContentState.field_set.length > 9) return;
    setCurQuestionContentState({
      ...curQuestionContentState,
      field_set: [
        ...curQuestionContentState.field_set,
        {
          content: null,
          id: `uId_${Date.now()}`,
          name: null,
          order: null,
          placeholder: '',
          question: null,
          type: {
            description: null,
            id: null,
            type: 'Plain Text',
          }
        }
      ]
    });
    setIsNewAddedOption(true);
  };

  const onDeleteOption = (curOptionContentState) => {
    setCurQuestionContentState({
      ...curQuestionContentState,
      field_set: curQuestionContentState.field_set.filter(option => option.id !== curOptionContentState.id)
    });
    setNewOption(newOption.filter(option => option.id !== curOptionContentState.id));
    setRemovedDeletedOptions(
      [...removedDeletedOptions, curOptionContentState.id]
    );
  };

  const saveQuestion = () => {
    newQuestion.field_set.forEach(e => {
      if (typeof e.id === 'string') {
        delete e.id;
      }
    });

    dispatch(newQuestion.question_id ? updateQuestions(newQuestion) : createQuestions(newQuestion));

    setNewQuestion({});
    setNewOption([]);
    setRemovedDeletedOptions([]);
  };

  const handleQuestionDelete = () => {
    dispatch(deleteQuestions(curQuestionContentState.id, curQuestionContentState.subsection));
  };

  const onLeaveInput = (e) => {
    if ((e.key === 'Enter') && !e.shiftKey) {
      e.target.blur();
    }
  };

  return (
    <div className="groupContent">
      <InputGroup className="mt-3 groupName_question">
        <FormControl
          id={curQuestionContentState?.id}
          name="question_name"
          as="textarea"
          aria-label="Type the question"
          aria-describedby="inputGroup-sizing-default"
          placeholder="Type the question"
          onChange={(e) => handleOnQuestionChange(e)}
          defaultValue={curQuestionContentState?.title}
          onKeyPress={e => onLeaveInput(e)}
          disabled={curQuestionContentState?.is_default}
        />
        <Dropdown className="groupName_question_dropdown" name="field_set">
          <Dropdown.Toggle disabled={curQuestionContentState?.is_default} id="question-dropdown-options">
            {curQuestionContentState?.type.type || questionDropdown?.type}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              questTypes.map(type => {
               return (
                 <Dropdown.Item onClick={(e) => onChangeQuestionType(e)} disabled={type.type !== 'Open Ended'} key={type.id} id={type.id}>{type.type}</Dropdown.Item>
               );
              })
            }
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="dots">
          <Dropdown.Toggle id="dropdown-options">
            <span>{dotsIcon}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={curQuestionContentState?.is_new || curQuestionContentState?.is_default}
              className={curQuestionContentState?.is_new ? 'disabled' : ''}
            >
              {duplicateIcon} Duplicate
            </Dropdown.Item>
            <Dropdown.Item
              disabled={curQuestionContentState?.is_new || curQuestionContentState?.is_default}
              className={curQuestionContentState?.is_new ? 'disabled' : ''}
            >
              {disableIcon} Disable
            </Dropdown.Item>
            {/*<Dropdown.Item>{enableIcon} Enable</Dropdown.Item>*/}
            <Dropdown.Item disabled={curQuestionContentState?.is_default || (curQuestionContentState?.explanation === '')} onClick={() => onAddExplain()}>{infoIcon} Add explanation</Dropdown.Item>
            <Dropdown.Item disabled={curQuestionContentState?.is_default || (curQuestionContentState?.hint === '')} onClick={() => onAddHint()}>{hintIcon} Add hint</Dropdown.Item>
            <Dropdown.Item
              disabled={curQuestionContentState?.is_default || curQuestionContentState?.is_new}
              onClick={() => setShowQuestionDeleteModal(!showQuestionDeleteModal)}
              className={curQuestionContentState?.is_default || curQuestionContentState?.is_new ? 'disabled' : ''}
            >
              {deleteIcon} Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
      {(curQuestionContentState?.explanation !== null) &&
        <InputGroup className="mt-3 groupName_question_hint">
          <div className={curQuestionContentState?.is_default ? 'disabled' : ''}>
            <span>{infoIcon}</span>
            <FormControl
              name="explanation"
              as="textarea"
              aria-label="Info"
              aria-describedby="inputGroup-sizing-default"
              placeholder="Type explanation"
              defaultValue={curQuestionContentState?.explanation}
              onChange={(e) => handleOnQuestionChange(e)}
              onKeyPress={e => onLeaveInput(e)}
              disabled={curQuestionContentState?.is_default}
            />
          </div>
          {
            !curQuestionContentState?.is_default && <span className="xIcon" onClick={() => onDeleteExplain()}>{deleteIcon}</span>
          }
        </InputGroup>}
      {(curQuestionContentState?.hint !== null) &&
        <InputGroup className="mt-3 groupName_question_hint">
          <div className={curQuestionContentState?.is_default ? 'disabled' : ''}>
            <span>{hintIcon}</span>
            <FormControl
              name="hint"
              as="textarea"
              aria-label="Hint"
              aria-describedby="inputGroup-sizing-default"
              placeholder="Type hint"
              defaultValue={curQuestionContentState?.hint}
              onChange={(e) => handleOnQuestionChange(e)}
              onKeyPress={e => onLeaveInput(e)}
              disabled={curQuestionContentState?.is_default}
            />
          </div>
          {
            !curQuestionContentState?.is_default && <span className="xIcon" onClick={() => onDeleteHint()}>{deleteIcon}</span>
          }
        </InputGroup>}

      {
        curQuestionContentState?.field_set && curQuestionContentState?.field_set.map((option, index) => {
          if (curQuestionContentState?.type.type === 'Open Ended') return (
            <OpenEnded
              key={option.id}
              setNewOption={setNewOption}
              addOption={addOption}
              onDeleteOption={onDeleteOption}
              curOptionContent={option}
              index={index}
              onLeaveInput={onLeaveInput}
              inputError={inputError}
              setInputError={setInputError}
            />
          );
          if (curQuestionContentState?.type.type === 'Single Select') return (
            <SingleSelect key={option.id} curOptionContent={option} />
          );
          if (curQuestionContentState?.type.type === 'Multi Select') return (
            <MultiSelect key={option.id} curOptionContent={option} />
          );
          //todo this will be active later
          // if (curQuestionContentState?.type.type === 'Nested') return (
          //     <Nested />
          // );
        })
      }
      {
        !!inputError.isValid && !(inputError.hasSpaces || inputError.hasValidLength) && (!!(newQuestion?.question_name?.trim() && newQuestion?.field_set?.length && !newQuestion?.field_set?.filter(e => e.placeholder === '').length) ||
        ((!!newQuestion?.hint?.trim() || (!!newQuestion?.hint && !!curQuestionContentState?.hint)) ||
        (!!newQuestion?.explanation?.trim() || (!!newQuestion?.explanation && !!curQuestionContentState?.explanation))) ||
        (curQuestionContentState?.title && newQuestion?.question_name) ||
        (curQuestionContentState?.title && newQuestion?.field_set?.length && !newQuestion?.field_set?.filter(e => e.placeholder === '').length) ||
        (newQuestion?.field_set?.length && isNewAddedOption)) && (newQuestion?.question_name !== '') && (!newQuestion?.field_set.filter(e => e.placeholder === '').length) &&
        (!curQuestionContentState?.field_set.filter(e => e.placeholder === '').length || (newQuestion?.field_set?.length && !(newOption.length !== curQuestionContentState?.field_set.filter(e => e.placeholder === '').length))) ||
        (removedDeletedOptions.length && (newQuestion?.question_name !== '')) ?
          <Button
            className={`groupSave ${isLoading ? 'groupSave spinnerSave' : ''}`}
            onClick={saveQuestion}
          >
            {
              isLoading ? <Spinner animation="border" /> : <span>Save</span>
            }
          </Button>
            :
          <Button
            className="groupSave"
            variant="light"
            disabled
          >
            {
              isLoading ? <Spinner animation="border" /> : (curQuestionContentState && curQuestionContentState.id) ? 'Saved' : 'Save'
            }
          </Button>
      }
      <hr />
      <QuestionDeleteModal
        show={showQuestionDeleteModal}
        onHide={() => setShowQuestionDeleteModal(!showQuestionDeleteModal)}
        handleQuestionDelete={handleQuestionDelete}
      />
    </div>
    );
};


export default Questions;