import {UserActionsTypes} from '../types';

const initialState = {
    sidebarInfo: {},
    quest_types: [],
    loading: false,
};

export const getSubsectionInfoReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case UserActionsTypes.TOGGLE_NEW_INFO_LOADING:
            return {
                ...state,
                loading: true
            };
        case UserActionsTypes.NEW_INFO:
            return {
                ...state,
                sidebarInfo: {
                    ...payload
                },
                loading: false
            };
        case UserActionsTypes.QUESTION_TYPES:
            return {
                ...state,
                quest_types: [...payload],
                loading: false
            };
        default:
            return state;
    }
};

export default getSubsectionInfoReducer;
