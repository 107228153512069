import {Button, Modal, Spinner} from 'react-bootstrap';
import React from 'react';
import {useSelector} from 'react-redux';

export const GroupDeleteModal = ({show, onHide, handleGroupDelete}) => {
  const isLoading = useSelector(state => state.getSubsectionInfo.loading);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      dialogClassName="modal-size"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <p>
          Are you sure you want to delete the group? This will permanently delete the group with all the components in it.
        </p>
        <div className="actions"> 
          <Button variant="light" onClick={onHide}>Cancel</Button>
          <Button variant="primary" onClick={handleGroupDelete}>
            {
              !isLoading ?
                <span>Delete</span>
                  : <Spinner animation="border" />
              }
          </Button>
        </div>
      </Modal.Body>
    </Modal>
    );
};