import React from 'react';
import {Form, FormControl, InputGroup} from 'react-bootstrap';
import {deleteIcon, plusIcon} from '../../../../../components/Icons/Icons';

const MultiSelect = ({curOptionContent}) => {

    return (
      <>
        {
            Object.keys(curOptionContent?.content).map((option, index) => {
                return (
                  <InputGroup
                    key={index} 
                    className="mt-3 groupName_question_option_withChecks"
                  >
                    <Form.Check aria-label="option 1" />
                    <FormControl
                      id={curOptionContent.id}
                      name="option_name"
                      aria-label="Option"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="Option"
                      defaultValue={option}
                    />
                    {/*{*/}
                    {/*      index <= 1 && <span>{plusIcon}</span>*/}
                    {/*  }*/}
                    {/*{*/}
                    {/*      index > 1 &&<span className="xIcon">{deleteIcon}</span>*/}
                    {/*  }*/}
                  </InputGroup>
                );
            })
        }
      </>
    );
};

export default MultiSelect;