import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import store from './store';
import {SignIn} from './components/SignIn';
import {Layout} from './components/Layout';
import {getSubsectionInfo} from './store/Actions';

import {Container, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

//Dispatch the getSubsectionInfo() before root component renders
localStorage.getItem('admin') && store.dispatch(getSubsectionInfo());

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <>
        <Container fluid>
          <Row>
            <Switch>
              {
                !localStorage.getItem('admin') ?
                  <>
                    <Redirect to="/sign-in" />
                    <Route exact path="/sign-in" component={SignIn} />
                  </>
                    :
                  <Route component={Layout} />
                  }
            </Switch>
            {/*<Route component={NotFound} />*/}
          </Row>
        </Container>
      </>
    </Router>
  </Provider>
    , document.getElementById('root')
);

