import {UserActionsTypes} from '../types';

const initialState = {
    isAuthUser: !!localStorage.getItem('user'),
    newLoggingUser: {},
    loading: false,
};

export const signInProcessReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case UserActionsTypes.TOGGLE_LOADING_SIGN_IN:
            return {
                ...state,
                loading: true
            };
        case UserActionsTypes.LOGIN_REQUEST:
            return {
                ...state,
                newLoggingUser: {
                    ...payload
                },
                isAuthUser: true,
                loading: false
            };
        default:
            return state;
    }
};

export default signInProcessReducer;
