import React, {useEffect, useState} from 'react';
import {Questions} from '../Grouped/Questions';

import {Button, OverlayTrigger, Row} from 'react-bootstrap';
import {plusIcon, questionIcon} from '../../../components/Icons/Icons';

const initialQuestion = {
    explanation: null,
    field_set: [{
        content: null,
        id: `uId_${Date.now()}`,
        name: null,
        order: null,
        placeholder: '',
        question: null,
        is_default: false,
        type: {
            description: null,
            id: null,
            type: 'Plain Text',
        }
    }],
    hint: null,
    id: null,
    order: null,
    subsection: null,
    title: '',
    is_default: false,
    is_new: true,
    type: {id: null, type: 'Open Ended', description: null}
};

const optionsPopover = (
  <div className="addingBlock">
    {questionIcon}
    <div>
      <span>Add Question</span>
      <p>Just start writing with plain text.</p>
    </div>
  </div>
);

const Ungrouped = ({subSectionId, curQuestionContent}) => {
    const [curQuestionSet, setCurQuestionSet] = useState([]);

    useEffect(() => {
        setCurQuestionSet(curQuestionContent);
    }, [curQuestionContent]);

    const onAddQuestion = () => {
        setCurQuestionSet([...curQuestionSet, initialQuestion]);
    };

    return (
      <Row className="mainContentBlock cloud">
        <Row className="mainContent">
          {
              curQuestionSet.map((question, index) => {
                  return (
                    <Questions key={question.id} curGroup={curQuestionContent} subSectionId={subSectionId} index={index} curQuestionContent={question} />
                  );
              })
          }
          {!curQuestionSet.filter(e => e.title === '').length &&
            <OverlayTrigger
              placement="bottom-start"
              overlay={optionsPopover}
            >
              {({ref, ...triggerHandler}) => (
                <Button
                  variant="light"
                  ref={ref}
                  {...triggerHandler}
                  className="d-inline-flex align-items-center overlayBtn questBtn"
                  onClick={() => onAddQuestion()}
                >
                  {plusIcon}
                </Button>
              )}
            </OverlayTrigger>}
        </Row>
      </Row>
    );
};


export default Ungrouped;