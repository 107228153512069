import {UserActionsTypes} from '../types';
import axios from 'axios';
import {SERVER} from '../../utils/constants';

export const logUser = (newLoggingUser) => async (dispatch, getState) => {
    dispatch(setLoader());
    axios.post(`${SERVER}user/sign-in/`, newLoggingUser)
        .then((response) => {
            dispatch(newUserLogin(response));
            if (response?.data?.access) {
                localStorage.setItem('admin', JSON.stringify(response.data));
            }
        })
        .catch(error => {
            dispatch(newUserLogin(error?.response?.data));
        });
};

const newUserLogin = (newLoggingUser) => {
    return {
        type: UserActionsTypes.LOGIN_REQUEST,
        payload: newLoggingUser
    };
};

const setLoader = () => {
    return {
        type: UserActionsTypes.TOGGLE_LOADING_SIGN_IN,
    };
};