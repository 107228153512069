import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getSubsectionContent} from '../../store/Actions';

import {Navbar, Nav, FormControl, NavDropdown, Button, Dropdown, Collapse} from 'react-bootstrap';
import {chart, dataSearch, legalPadIcon, portrait, setting, searchIcon} from '../Icons/Icons';
import './Sider.scss';

const Sider = (callback, deps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // initial state of dropdownTitle will become "Visa Type" after having other than O-1A
    const [dropdownTitle, setDropdownTitle] = useState('O-1A');
    const [showVisaDropdown, setShowVisaDropdown] = useState(false);
    const [sectionOpen, setSectionOpen] = useState({});
    const [subSectionOpen, setSubSectionOpen] = useState({});
    const sidebarContent = useSelector(state => state.getSubsectionInfo.sidebarInfo['0']);

    useEffect(() => {
        !!localStorage.getItem('openedSubsection') && setShowVisaDropdown(true);
        !!localStorage.getItem('openedSubsection') && setSubSectionOpen(prev => ({
            [localStorage.getItem('openedSubsection')]: !prev[localStorage.getItem('openedSubsection')],
        }));
    }, []);

    const visaTypes = (
      <NavDropdown id="visaTypes" title={dropdownTitle} className="visaTypes">
        <NavDropdown.Item
          id={sidebarContent?.name}
          onClick={(e) => setDropdownTitle(e.target.id)}
        >
          {sidebarContent?.name}
        </NavDropdown.Item>
      </NavDropdown>
    );

    const goSubsection = (title) => {
        setSubSectionOpen(!subSectionOpen);
        history.push({
            pathname: `/data-intake/visa=O-1/${title}`,
        });
    };

    const openSection = (e) => {
        setSectionOpen(prev => ({
            ...sectionOpen,
            [e]: !prev[e],
        }));
    };

    const openSubSection = (subsection) => {
        dispatch(getSubsectionContent(subsection.id));
        localStorage.setItem('openedSubsection', subsection.id);
        goSubsection(subsection.title);

        setSubSectionOpen(prev => ({
            // ...subSectionOpen,
            [subsection.id]: !prev[subsection.id],
        }));
    };

    const visaO1 = (
      <div className="visaO-1Block">
        {
            sidebarContent?.sections.map(section => {
                return (
                  <React.Fragment key={section.id}>
                    <NavDropdown
                      id={section.id}
                      title={section.title}
                      aria-controls="collapse-items"
                      className={(+localStorage.getItem('openedSection') === section.id) && 'show'}
                      onClick={() => openSection(section.id)}
                      aria-expanded={(+localStorage.getItem('openedSection') === section.id) && sectionOpen[section.id]}
                      rootCloseEvent={false}
                    />
                    <Collapse
                      key={section.id}
                      in={sectionOpen[section.id]}
                      className="visaO-1Block_collapse"
                    >
                      <ul id="collapse-items">
                        {
                            section.subsections.map(subsection => {
                                return (
                                  <React.Fragment key={subsection.id}>
                                    <li
                                      aria-controls="collapse-items"
                                      onClick={() => openSubSection(subsection)}
                                      aria-expanded={subSectionOpen[subsection.id]}
                                      className={`${subSectionOpen[subsection.id] && 'curShowing'}`}
                                    >
                                      {subsection.title}
                                    </li>
                                    <Collapse 
                                      key={subsection.id} 
                                      in={subSectionOpen[subsection.id]}
                                      className="collapse-groups"
                                    >
                                      <ul id="collapse-items">
                                        {
                                            subsection.groups.map(group => {
                                                return (
                                                  <li key={group.id}>
                                                    <hr />
                                                    <span>{group.title}</span>
                                                  </li>
                                                );
                                            })
                                        }
                                      </ul>
                                    </Collapse>
                                  </React.Fragment>
                                    );
                                })
                            }
                      </ul>
                    </Collapse>
                  </React.Fragment>
                    );
                })
            }
      </div>
    );

    const onSelectedKey = (e) => {
        if (e === 'Data Intake') {
            setShowVisaDropdown(true);
        }
    };

    const goBackDashboard = (e) => {
        !!localStorage.getItem('openedSubsection') && localStorage.removeItem('openedSubsection');
        setShowVisaDropdown(false);
        setSectionOpen({});
        history.push({
            pathname: '/',
        });
    };
    return (
      <Nav
        className="col-md-12 d-none d-md-block sidebar"
        activeKey="/home"
        onSelect={e => onSelectedKey(e)}
      >
        <Navbar.Brand onClick={goBackDashboard}>{legalPadIcon}</Navbar.Brand>
        {
            showVisaDropdown &&
              <Button className="backBtn" onClick={goBackDashboard}>
                <span className="arrow" />
                Back to Dashboard
              </Button>
        }
        {showVisaDropdown && visaTypes}
        {
            !showVisaDropdown &&
              <>
                <Nav.Item className="searchBar">
                  <div>
                    {searchIcon}
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                  </div>
                </Nav.Item>
                <Nav.Item className="tab">
                  {portrait} <Nav.Link eventKey="User Management">User Management</Nav.Link>
                </Nav.Item>
                <Nav.Item className="tab">
                  {chart} <Nav.Link eventKey="Statistics">Statistics</Nav.Link>
                </Nav.Item>
                <Nav.Item className="tab">
                  {dataSearch} <Nav.Link eventKey="Data Intake">Data Intake</Nav.Link>
                </Nav.Item>
                <Nav.Item className="tab">
                  {setting} <Nav.Link eventKey="Settings">Settings</Nav.Link>
                </Nav.Item>
              </>
        }
        {
            (dropdownTitle === 'O-1A') && showVisaDropdown && visaO1
        }
      </Nav>
    );
};

export default Sider;