import { combineReducers } from 'redux';

/* Reducers */
import getSubsectionInfoReducer from './getSubsectionInfo.reducer';
import signInProcessReducer from './signInProcces.reducer';
import signOutProcessReducer from './signInProcces.reducer';
import getSubsectionContentReducer from './getSubsectionContent.reducer';
import cudQuestionsReducer from './cudQuestions.reducer';
import publishQuestionsReducer from './publishQuestions.reduce';

const rootReducer = combineReducers({
    getSubsectionInfo: getSubsectionInfoReducer,
    signIn: signInProcessReducer,
    signOut: signOutProcessReducer,
    getSubsectionContent: getSubsectionContentReducer,
    cudQuestions: cudQuestionsReducer,
    publishQuestions: publishQuestionsReducer
});

export default rootReducer;