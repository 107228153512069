import React from 'react';
import {Col} from 'react-bootstrap';
import {Sider} from '../Sider';
import {HeadNavbar} from '../HeadNavbar';
import {Pages} from '../../routes';

const Layout = () => {
    return (
      <>
        <Col lg="auto" md="auto" id="sidebar-wrapper">
          <Sider />
        </Col>
        <Col id="page-content-wrapper">
          <HeadNavbar />
          <Pages />
        </Col>
      </>
    );
};

export default Layout;
