import {UserActionsTypes} from '../types';
import axios from 'axios';
import {SERVER} from '../../utils/constants';

export const getSubsectionInfo = (newInfo) => async (dispatch, getState) => {
    dispatch(setLoader());
    if (newInfo) {
        if (typeof newInfo === 'number') {
            axios.delete(`${SERVER}api/group/${newInfo}/`, )
                .then((response) => {
                    axios.get(`${SERVER}api/sidebar_data/`)
                        .then((response) => {
                            dispatch(subSectionNewInfo(response.data));
                        })
                        .catch(error => {
                            dispatch(subSectionNewInfo(error));
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        } else if (newInfo.id) {
            axios.put(`${SERVER}api/group/${newInfo.id}/`, {
                'title': newInfo.group_name, ...newInfo
            })
                .then((response) => {
                    axios.get(`${SERVER}api/sidebar_data/`)
                        .then((response) => {
                            dispatch(subSectionNewInfo(response.data));
                        })
                        .catch(error => {
                            dispatch(subSectionNewInfo(error));
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            axios.post(`${SERVER}api/create-group/`, {
                'title': newInfo.group_name, ...newInfo
            })
                .then((response) => {
                    axios.get(`${SERVER}api/sidebar_data/`)
                        .then((response) => {
                            dispatch(subSectionNewInfo(response.data));
                        })
                        .catch(error => {
                            dispatch(subSectionNewInfo(error));
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    axios.get(`${SERVER}api/sidebar_data/`)
        .then((response) => {
            dispatch(subSectionNewInfo(response.data));
        })
        .catch(error => {
            dispatch(subSectionNewInfo(error));
        });
    axios.get(`${SERVER}api/question-types/`)
        .then(response => {
            dispatch(questionTypes(response.data));
        })
        .catch(error => {
            dispatch(questionTypes(error));
        });
};

const subSectionNewInfo = (sidebarInfo) => {
    return {
        type: UserActionsTypes.NEW_INFO,
        payload: sidebarInfo
    };
};

const questionTypes = (questionTypes) => {
    return {
        type: UserActionsTypes.QUESTION_TYPES,
        payload: questionTypes
    };
};

const setLoader = () => {
    return {
        type: UserActionsTypes.TOGGLE_NEW_INFO_LOADING,
    };
};