import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {Dashboard} from './pages/Dashboard';
import {Subsection} from './pages/Subsection';

import './pages/global.scss';

export const Pages = () => {

    return (
      <div>
        <Switch>
          <Route exact path="/sign-in">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/data-intake/visa=O-1/:title" component={Subsection} />
        </Switch>
      </div>
    );
};