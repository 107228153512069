import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {logUser} from '../../store/Actions';

import {closedEyeIcon, openEyeIcon} from '../Icons/Icons';
import {Button, Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import '../../pages/global.scss';
import './SignIn.scss';

const initialState = {
    email: '',
    password: '',
};

const inputsVerificationError = {
    hasValidEmail: true,
};

const SignIn = ({}) => {
    const dispatch = useDispatch();
    const [loggingUser, setLoggingUser] = useState(initialState);
    const [passwordShown, setPasswordShown] = useState(false);
    const [inputError, setInputError] = useState(inputsVerificationError);
    const loginResponse = useSelector(state => state.signIn.newLoggingUser);
    const isLoading = useSelector(state => state.signIn.loading);

    useEffect(() => {
        !!localStorage.getItem('admin') && window.location.reload(false);
    });

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleOnChange = (e) => {
        const {name, value} = e.target;
        setLoggingUser({...loggingUser, [name]: value});
        if (name === 'email') {
            const hasValidEmail = !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

            setInputError({
                ...inputError,
                hasValidEmail,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const {email, password} = loggingUser;

        const newLoggingUser = {
            email,
            password
        };

        dispatch(logUser(newLoggingUser));
    };

    return (
      <div className="formBlock">
        <Container fluid="sm" className="formCont signIn">
          <Row className="signIn_header">
            <Col>
              <h1>Sign in to Legalpad</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    name="email"
                    className={(loginResponse?.newLoggingUser?.response?.statusText === 'Unauthorized') && 'error-border'}
                    value={loggingUser.email}
                    onChange={handleOnChange}
                    placeholder="Email"
                    required
                  />
                  <p className="mt-1 error">
                    {loggingUser.email &&
                        inputError.hasValidEmail && 'You have entered an invalid e-mail address'}
                  </p>
                </Form.Group>
                <Form.Group className="passInputLog">
                  <Form.Control
                    type={passwordShown ? 'text' : 'password'}
                    className={(loginResponse?.newLoggingUser?.response?.statusText === 'Unauthorized') && 'error-border'}
                    name="password"
                    value={loggingUser.password}
                    onChange={handleOnChange}
                    placeholder="Password"
                    required
                  />
                  <div className="toggleEye" onClick={togglePasswordVisibility}>
                    {passwordShown ? openEyeIcon  : closedEyeIcon}
                  </div>
                  {loginResponse.error &&
                    <p className="mt-1 error">
                      Invalid email or password.
                    </p>}
                </Form.Group>

                <Button
                  className="submit-btn"
                  variant="primary"
                  type="submit"
                  disabled={Object.values(inputError).includes(true) || !loggingUser.password || isLoading}
                >
                  {!isLoading ?
                    <span>Sign in</span> :
                    <Spinner animation="border" />}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
};

export default SignIn;